import { LoadingProcess, UseStart } from '../types';
import {
  pushNotificationServicePlugin,
  PushProviderPermissionError,
} from '@/features/push-notification';
import { Order, ordersServicePlugin } from '@/features/orders';
import { oauthServicePlugin } from '@/features/oauth';
import { User, userServicePlugin } from '@/features/user';
import { ref } from 'vue';
import { HandledError, errorPlugin } from '@/features/core/errors';
import {
  NoMerchantForUserError,
  PushPermissionError,
} from '@/features/login/errors';
import { authServicePlugin } from '@/features/core/auth';
import { configurationServicePlugin } from '@/features/configuration';
import { appServiceWorkerServicePlugin } from '@/features/service-worker';
import { BackgroundSyncTags } from '@/features/data-background-sync/types';
import { tourCrashServicePlugin } from '@/features/tour-crash';
import { orderMetadataServicePlugin } from '@/features/orderMetadata';
import { toursServicePlugin } from '@/features/tours';
import { retrySynchronisationsPlugin } from '@/features/retry-synchronisation';
import { loggerServicePlugin } from '@/features/core/logger';
import { deviceServicePlugin } from '@/features/device-info';
import { surveyServiceClientPlugin } from '@/features/survey';
import { dataFetchQueueServicePlugin } from '@/features/data-fetch-queue';

export function useStart(): UseStart {
  const loadingData = ref({
    configuration: LoadingProcess.NotStarted,
    data: LoadingProcess.NotStarted,
    sync: LoadingProcess.NotStarted,
    order: LoadingProcess.NotStarted,
    tour: LoadingProcess.NotStarted,
  });

  const configurationLoading = async () => {
    loadingData.value.configuration = LoadingProcess.Loading;
    errorPlugin.get().setVisibleErrorPopup(true);
    await configurationServicePlugin.get().fetchAllConfigurations();
    errorPlugin.get().setVisibleErrorPopup(false);
    loadingData.value.configuration = LoadingProcess.Loaded;
  };

  const pushNotificationSubscribe = async (user: User) => {
    if (!userServicePlugin.get().isDriverRole(user.roles)) {
      const pushNotificationService = pushNotificationServicePlugin.get();
      pushNotificationService.init();
      await deviceServicePlugin.get().updateAndSync();
    }
  };

  const orderLoading = async (withBackgroundFullSync = true) => {
    const orders = await ordersServicePlugin.get().fetchAllOrders();
    await orderMetadataServicePlugin.get().clearAll();
    await orderMetadataServicePlugin.get().checkAndSetOrdersMetadata(orders);

    if (withBackgroundFullSync) {
      await appServiceWorkerServicePlugin
        .get()
        .notifySync(BackgroundSyncTags.NormalBackgroundSync);

      await appServiceWorkerServicePlugin
        .get()
        .registerPeriodicSync(BackgroundSyncTags.PeriodicBackgroundSync);
    }
    return orders;
  };

  const tourLoading = async () => {
    loadingData.value.tour = LoadingProcess.Loading;
    const tours = await toursServicePlugin.get().fetchAllTours();
    loadingData.value.tour = LoadingProcess.Loaded;

    return tours;
  };

  const loadDriverData = async (): Promise<string> => {
    const pickingOrderDependencyActive = await configurationServicePlugin
      .get()
      .getFeatureOption(
        'driverApp',
        'pickingOrdersDependencyActive',
        'boolean',
      );

    loadingData.value.order = LoadingProcess.Loading;
    let orders: Order[] = [];
    if (!pickingOrderDependencyActive) {
      orders = await orderLoading(false);
    }
    loadingData.value.order = LoadingProcess.Loaded;

    const tours = await tourLoading();
    if (!pickingOrderDependencyActive) {
      toursServicePlugin.get().logMissingOrderReferences(orders, tours);
    }

    await tourCrashServicePlugin.get().init();

    return '/tours';
  };

  const loadPickerData = async (user: User): Promise<string> => {
    errorPlugin.get().setVisibleErrorPopup(true);

    loadingData.value.sync = LoadingProcess.Loading;
    await pushNotificationSubscribe(user);
    loadingData.value.sync = LoadingProcess.Loaded;

    loadingData.value.order = LoadingProcess.Loading;
    await orderLoading();
    await dataFetchQueueServicePlugin.get().removeAllOfEntity(Order);
    errorPlugin.get().setVisibleErrorPopup(false);
    loadingData.value.order = LoadingProcess.Loaded;

    return '/';
  };

  const handleNoMerchantForUser = async (): Promise<void> => {
    await authServicePlugin.get().removeUserToken();
    errorPlugin.get().setVisibleErrorPopup(false);
    errorPlugin.get().handle(new NoMerchantForUserError());
  };

  const loadUserData = async (user: User): Promise<string> => {
    return userServicePlugin.get().isDriverRole(user.roles)
      ? await loadDriverData()
      : await loadPickerData(user);
  };

  const handlePushProviderPermissionError = async (): Promise<void> => {
    errorPlugin.get().setVisibleErrorPopup(false);
    errorPlugin.get().handle(new PushPermissionError());

    await oauthServicePlugin.get().logout('empowerId');
    await userServicePlugin.get().clearUser();
  };

  const handleDataLoadingError = async (error: Error): Promise<void> => {
    loggerServicePlugin.get().error(error);
    if (error instanceof PushProviderPermissionError) {
      await handlePushProviderPermissionError();
    } else {
      retrySynchronisationsPlugin.get().retry();
    }
  };

  const loadData = async () => {
    try {
      loadingData.value.data = LoadingProcess.Loading;
      await configurationLoading();
      await surveyServiceClientPlugin.get().clearAll();
      const user = await userServicePlugin.get().setUserByMerchant();
      loadingData.value.data = LoadingProcess.Loaded;

      return user.merchant.reference
        ? await loadUserData(user)
        : await handleNoMerchantForUser();
    } catch (error) {
      if (error instanceof HandledError) {
        return;
      }
      await handleDataLoadingError(error as Error);
    }
  };

  return {
    loadingData,
    loadData,
  };
}
